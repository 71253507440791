import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { InputBaseProps } from '@mui/material/InputBase'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import cn from 'classnames'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'

import HideIcon from 'components/Icons/Hide'
import ShowIcon from 'components/Icons/Show'

import m from './messages'
import PasswordStatus from './PasswordStatus'

type PasswordFieldProps = {
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  fullWidth?: boolean
  inputProps?: InputBaseProps['inputProps']
  showStatus?: boolean
  value?: unknown
} & TextFieldProps

function PasswordField({
  children,
  className,
  disabled,
  inputProps,
  showStatus = true,
  value,
  ...restProps
}: PasswordFieldProps) {
  const { formatMessage: f } = useIntl()
  const [hidePassword, setHidePassword] = useState(true)
  const testId = inputProps?.['data-test-id'] || 'password'

  return (
    <>
      <TextField
        {...restProps}
        className={cn(showStatus && '-mb3', className)}
        disabled={disabled}
        inputProps={{
          autoComplete: 'new-password',
          ...inputProps,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-test-id={`${testId}.toggleVisibility`}
                disabled={disabled}
                onClick={() => setHidePassword(!hidePassword)}
                size="large"
              >
                {hidePassword ? <ShowIcon /> : <HideIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        type={hidePassword ? 'password' : 'text'}
        value={value}
      />

      {children}

      {showStatus && (
        <>
          <PasswordStatus
            data-test-id={`${testId}.status.oneLowercaseCharacter`}
            success={Yup.string()
              .required()
              .matches(/(?=.*[a-z])/)
              .isValidSync(value)}
            text={f(m.oneLowercaseCharacter)}
          />

          <PasswordStatus
            data-test-id={`${testId}.status.symbolOrNumber`}
            success={Yup.string()
              .required()
              .matches(/(?=.*[0-9])/)
              .isValidSync(value)}
            text={f(m.symbolOrNumber)}
          />

          <PasswordStatus
            data-test-id={`${testId}.status.atLeast8CharactersLong`}
            success={Yup.string().required().min(8).isValidSync(value)}
            text={f(m.atLeast8CharactersLong)}
          />
        </>
      )}
    </>
  )
}

export default PasswordField
