import { defineMessages } from 'components/LanguageProvider'

const messagesBase = {
  atLeast8CharactersLong: {
    defaultMessage: 'At least 8 characters long',
    id: 'base.atLeast8CharactersLong',
  },
  oneLowercaseCharacter: {
    defaultMessage: 'One lowercase character',
    id: 'base.oneLowercaseCharacter',
  },
  symbolOrNumber: {
    defaultMessage: 'A symbol or a number',
    id: 'base.symbolOrNumber',
  },
}

export default defineMessages({
  ...messagesBase,
})
