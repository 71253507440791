import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import SuccessIcon from 'components/Icons/Success'

type PasswordStatusProps = {
  success: boolean
  text: React.ReactNode
}

const useStyles = makeStyles({
  name: 'UserCreatePasswordStatus',
})((theme) => ({
  root: {
    '& svg': {
      marginRight: theme.spacing(2),
    },
    color: theme.palette.grey['400'],
  },
  success: {
    color: theme.palette.success.main,
  },
}))

function PasswordStatus({ success, text, ...restProps }: PasswordStatusProps) {
  const { classes, cx } = useStyles()
  const textColor = success ? 'textSecondary' : 'inherit'

  return (
    <Grid
      alignItems="center"
      className={cx(classes.root, success && classes.success)}
      container
      {...restProps}
    >
      <SuccessIcon color="inherit" />
      <Typography color={textColor}>{text}</Typography>
    </Grid>
  )
}

export default PasswordStatus
